import { Hero, SectionCallout, SEO } from "Components"
import Config from "Data"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "Layouts/simple"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { InlineLink, Wrapper } from "Styles"
import { theme } from "Theme"

const Content = styled.div`
  text-align: left;
  height: 100%;
  width: 100%;
  max-width: ${theme.layout.base};
  position: relative;
  z-index: 3;
  margin: 2rem 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    margin: 2rem auto 2rem;
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`

const query = graphql`
  {
    hero: allGoogleSheetsHomepage(filter: { block: { eq: "Hero" } }) {
      edges {
        node {
          id
          block
          heroImage
          title
          subtitle
          childrenFile {
            childImageSharp {
              fluid {
                src
              }
              gatsbyImageData(width: 2880, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`

const ErrorPage = () => {
  const { hero } = useStaticQuery(query)
  const background = hero.edges[0].node

  return (
    <Layout locale={"en-us"} region={"us"} simple={true}>
      <SEO
        pathname="/404"
        title={`404 - Page not found | CXO Events | ${Config.brandBusiness} by ${Config.contact.name}`}
        description={`This page does not exist or is no longer reachable.`}
        banner={background.childrenFile[0].childImageSharp.fluid.src || ""}
        socialShare={{ hashtags: [`${Config.contact.name}`], event: "" }}
      />
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Hero
        animated={true}
        blur={true}
        color={"default"}
        height={50}
        image={background.childrenFile[0].childImageSharp.gatsbyImageData}
        justify={"flex-end"}
        subtitle={`This page does not exist or is no longer reachable.`}
        title={`Woops, something went wrong.`}
      />
      <SectionCallout small={true} dark={false}>
        <Wrapper>
          <Content>
            <h3>
              You can return to the <InlineLink to="/">Homepage</InlineLink>.
            </h3>
          </Content>
        </Wrapper>
      </SectionCallout>
    </Layout>
  )
}

export default ErrorPage
